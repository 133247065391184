<template>
  <div class="user">
    <div class="form_info">
      <el-form ref="form" :model="queryInfo" label-width="100px">
        <div class="form">
          <div class="comble_txt">
            <el-form-item label="报备状态">
              <el-radio-group v-model="queryInfo.reportingStatus" @change="search">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button :label="1">报备中</el-radio-button>
                <el-radio-button :label="2">报备成功</el-radio-button>
                <el-radio-button :label="3">报备失败</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="所在地区:" prop="">
              <el-select v-model="queryInfo.province" placeholder="请选择省份" @change="citychange">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in operatingList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <div style="display: inline-block; width: 10px"></div>
              <el-select v-model="queryInfo.city" placeholder="请选择市" @change="districtchange">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in operatingLists"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <div style="display: inline-block; width: 10px"></div>
              <el-select v-model="queryInfo.district" placeholder="请选择区" @change="search">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in districtList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label=" " label-width="0px" style="padding-top: 20px; margin-bottom: 0">
              <div class="input-with">
                <div style="display: flex">
                  <el-button type="primary" @click="handleAdd" v-if="$hasPerms('custom:add')">新增</el-button>

                  <div style="margin-left: 10px">
                    <el-upload
                      class="upload-demo"
                      action
                      accept=".xls, .xlsx"
                      :before-upload="beforeUpload"
                      :multiple="false"
                      :http-request="uploadSuccess"
                    >
                      <el-button type="primary" plain v-if="$hasPerms('import')">批量导入</el-button>
                    </el-upload>
                  </div>
                  <el-button style="margin-left: 10px" v-if="$hasPerms('/report')"  @click="checkClick" plain
                    >批量报备</el-button
                  >
                  <div class="ckeckbox_text">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
            </div>
                </div>
                <div>
                  <el-input
                    placeholder="请输入企业名称"
                    v-model="queryInfo.companyFullName"
                    class="input-with-select"
                  >
                    <el-button class="seach" slot="append" @click="search()">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>

    <div class="tab_txt" v-loading="loading">
      <div style="display: flex; align-items: center; padding-bottom: 10px">
        <div style="padding-right: 50px">
          <span >当前条件下共查出{{ total }}家企业</span>
      
        </div>
        <div
          v-for="(item, index) in orderList"
          :key="index"
          style="display: flex; align-items: center; margin-right: 30px"
        >
          {{ item.name }}
          <div class="main">
            <span
              class="arrUp"
              @click="clickUp1(item)"
              :style="{ 'border-bottom-color': item.color1 }"
            ></span>
            <span
              class="arrDown"
              @click="clickDown1(item)"
              :style="{ 'border-top-color': item.color2 }"
            ></span>
          </div>
        </div>
      </div>
      <div class="storeCenter_item" v-for="item in listData" :key="item.id">
        <div class="item_checkbox">
          <el-checkbox
            @change="latechange($event, item)"
            v-model="item.checked1"
            label=""
          ></el-checkbox>
        </div>
        <div class="storeCenter_item_top">
          <div class="po_reportingStatus" v-if="item.reportingStatus == 3||item.reportingStatus==5">
           <img src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/%E6%8A%A5%E5%A4%87%E5%A4%B1%E8%B4%A5.png" alt="">
          </div>
          <div class="storeCenter_item_top_left">
            <img v-if="item.companyLogo" :src="item.companyLogo" alt="" />
            <img v-else src="../../assets/image/Group 3237.png" alt="" />
            <div class="storeCenter_item_top_left_flex">
              <div style="display: flex; align-items: center">
                <div v-if="item.reportingStatus == 2" class="reporting">
                  <span>报备成功</span>
                </div>
                <div v-if="item.reportingStatus == 1||item.reportingStatus==4" class="reporting">
                  <span>该企业报备中...</span>
                </div>

                <h5 @click="editcustom(item)" style="display: inline-block; margin: 0; font-size: 20px">
                  {{ item.companyFullName }}
                </h5>
              </div>
              <div class="storeCenter_item_top_left_flex_tag">
                <span v-if="item.companyLabelList" style="display: flex">
                  <span v-for="(o, index) in item.companyLabelList.slice(0, 3)" :key="index">
                    <span v-if="index < 3" class="office">{{ o }}</span>
                  </span>
                </span>
                <el-popover placement="right" width="430" trigger="hover">
                  <div
                    class="hidden_label_box"
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      align-content: center;
                      flex-wrap: wrap;
                    "
                  >
                    <span
                      class="office"
                      style="
                        border: 1px solid #559eff;
                        color: #4e93fb;
                        box-sizing: border-box;
                        padding: 1px 6px;
                        display: block;
                        font-size: 14px;
                        font-weight: 400;
                        border-radius: 4px;
                        margin-right: 10px;
                        margin-bottom: 10px;
                      "
                      v-for="(o, indexs) in item.companyLabelList"
                      :key="indexs"
                      >{{ o }}</span
                    >
                  </div>
                  <span
                    class="office"
                    slot="reference"
                    v-if="item.companyLabelList && item.companyLabelList.length >= 3"
                    >{{ item.companyLabelList.length }}+</span
                  >
                </el-popover>
                <div v-if="item.reportingStatus == 3" style="color: #ff7878; display: flex">
                  <i class="el-icon-warning"></i>
                  <span>{{ item.refuseReason }}</span>
                </div>
                <div v-if="item.reportingStatus == 5" style="color: #ff7878; display: flex">
                  <i class="el-icon-warning"></i>
                  <span>已超过两个月未有用户加入智参小程序并未绑定该企业</span>
                </div>
                <div v-if="item.reportingStatus == 1" style="color: #ff8c06; display: flex">
                  <i class="el-icon-warning"></i>
                  <span>智参平台已准备很多内容啦，请及时引导真实用户在智参小程序绑定该企业</span>
                </div>
              </div>
              <div style="display: flex; align-items: center">
                <p class="address" v-if="item.liaisonsCompanyResultList&&item.liaisonsCompanyResultList.length">
                  <span> {{ item.liaisonsCompanyResultList[0].liaisonsName }}</span>
                  <span style="padding: 0px 5px">
                    {{ item.liaisonsCompanyResultList[0].liaisonsContactWay }}</span
                  >
                </p>
                <span> {{ item.province }}-{{ item.city }}
                  <span v-if="item.district">-{{ item.district }}</span>
                  </span>
              </div>
            </div>
          </div>
          <div class="storeCenter_item_top_center">
            <div class="count_box" v-if="item.clientCompanyContent">
              <div>{{ item.clientCompanyContent }}</div>
              <div>内容</div>
            </div>
            <div class="count_box" v-if="item.clientCompanyActive ">
              <div>{{ item.clientCompanyActive }}</div>
              <div>活动</div>
            </div>
            <div class="count_box" v-if="item.clientCompanyReport">
              <div>{{ item.clientCompanyReport }}</div>
              <div>报告</div>
            </div>
            <div class="count_box" v-if="item.clientCompanySpecialist">
              <div>{{ item.clientCompanySpecialist }}</div>
              <div>专家</div>
            </div>
          </div>

          <div class="storeCenter_item_top_right">
            <div class="operation_area">
             
             <div  v-if="$hasPerms('custom:order')">
              <el-button  v-if="item.reportingStatus == 2" type="primary" @click="clickorder(item)"
                >订单管理
                <span v-if="item.numberOfOrders"> ({{ item.numberOfOrders }})</span>
              </el-button>
             </div>
            <div v-if="$hasPerms('custom:user')">
              <el-button v-if="item.reportingStatus != 3" type="info" @click="clcikuser(item)" plain
                >用户管理 <span v-if="item.userCount"> ({{ item.userCount }})</span></el-button
              >
            </div>
            <div v-if="$hasPerms('custom:diagnosis')">
              <el-button v-if="item.reportingStatus == 2" type="info" @click="records(item)" plain
                >诊断记录
                <span v-if="item.diagnoseCount">({{ item.diagnoseCount }})</span>
              </el-button>
            </div>
              <el-popover style="margin-right: 10px;" v-if="item.reportingStatus == 3" placement="top" width="380"   trigger="click">
                <p>①报备中情况一：没有机构报备A企业，则企业展示报备中的状态情况二：多家机构报备A企业，则按照报备规则展示企业报备中，如果A企业被前一位机构关联上，则展示报备失败（备注理由：该企业已被其他平台关联，请继续新增其他企业）</p>
                <p>②报备成功：机构报备在两个月之内，智参用户有在小程序绑定这家企业，则报备成功</p>
                <p>③报备失败：A机构报备在两个月之内，智参用户没有在小程序绑定这家企业，则报备失败；如果有B、C机构报备此企业，则按新增正序排列至B机构，B机构企业展示报备中的状态（如果B机构移除报备企业），则排列至C机构报备之后需要总后台审核才能正式进入报备流程</p>
                <el-button slot="reference">查看报备规则</el-button>
              </el-popover>
              <!-- <el-button v-if="item.reportingStatus == 3" type="info" plain>
                查看报备规则
              </el-button> -->
              <el-button
                v-if="item.reportingStatus == 3"
                @click="deleteById(item)"
                type="info"
                plain
              >
                移除
              </el-button>
              <span v-if="$hasPerms('custom:info')" class="edit" @click="editcustom(item)">详情</span>
            </div>
          </div>

          <div class="Relevancy" v-if="item.enterpriseCategory == '传统企业'">
            <span>{{ item.enterpriseCategory }}</span>
          </div>
          <div class="Relevancyblue" v-else-if="item.enterpriseCategory != null">
            <span>{{ item.enterpriseCategory }}</span>
          </div>
        </div>
      </div>
      <div class="new_page" v-if="listData.length">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { companylist, importCompany, companyReport, deleteById } from '@/api/datement'
import { getCityAll } from '@/api/demand'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  companyFullName: null, //企业全称
  city: null, //所在地区
  companyLabel: null, //企业标签
  orders: 1, //默认传1 2->新增时间升序;3->新增时间降序
  district: null, //区/县
  province: null, //所在省份
  userSchema: '',
  reportingStatus: null //报备状态(1->报备中;2->报备成功;3->报备失败)
})
export default {
  name: 'Userment',

  data() {
    return {
      ids: [],
      idsList: [],
      checkAll: false,
      isIndeterminate: false,
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      orderList: [
        {
          name: '按新增时间',
          color1: '',
          color2: '#C6C6C6',
          asc: 2,
          desc:3
        },
      ], //排序
      parteninfo:JSON.parse(sessionStorage.getItem('typeMode')),
      operatingList: [], //省
      operatingLists: [], //市
      districtList: [] //区
    }
  },
  created() {
    this.search()
    this.incity()
  },
  methods: {
    //批量导入
    beforeUpload(file) {
      //判断文件格式
      let hz = file.name.split('.')[1]
      if (hz != 'xlsx' && hz != 'xls') {
        this.$alert('只能上传EXCEL文件！')
        return false
      }
    },
   
    async uploadSuccess(params) {
      const files = new FormData()
      files.append('files', params.file)
      files.append('userSchema', this.parteninfo.id)
      this.loading=true
      const res = await importCompany(files)
      if (res.data.resultCode == 200) {
        this.loading=false
        this.$message({
          type: 'success',
          message: '导入成功!'
        })
        this.search()
      } else {
        this.$alert('添加失败!' + res.data.message)
      }
    },
    //移出
    deleteById(row) {
      const that = this
      const query = {
        reportId: row.reportId
      }
      this.$alert('是否确认移除？')
        .then(function () {
          return deleteById(query)
        })
        .then(() => {
          that.search()

          that.$message.success('移除成功')
        })
        .catch(() => {})
    },
    //批量报备
    checkClick() {
      if (this.ids.length > 0) {
        const that = this
        this.$alert('是否确认批量报备选中企业?')
          .then(function () {
            return companyReport(that.ids)
          })
          .then(() => {
            that.search()
            that.ids = []
            this.isIndeterminate = false
            this.checkAll = false
          
            that.$message.success('报备成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择企业')
      }
    },
    //诊断记录
    records(row) {
      this.$router.push({
        name: 'Records',
        query: { id: row.id }
      })
    },
    //订单管理
    clickorder(row) {
      this.$router.push({
        name: 'customorder',
        query: { id: row.id }
      })
      // this.$message.warning('待开发')
    },
    //用户管理
    clcikuser(row) {
      this.$router.push({
        name: 'CorrelateUser',
        query: { id: row.id }
      })
    },

    //城市
    async incity() {
      const res = await getCityAll()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    //选择城市
    citychange(item) {
      console.log(item)
      this.operatingList.forEach((o) => {
        if (this.queryInfo.province == o.name) {
          this.queryInfo.city = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            let isnum = item.indexOf('市')
            if (isnum == -1) {
              this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
            } else {
              this.queryInfo.city = item
              this.districtList = this.operatingList.find((el) => el.name == item).cityResults
            }
          }
        }
      })
      this.search()
    },
    //选择区
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.queryInfo.city == o.name) {
          this.queryInfo.district = ''
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
      this.search()
    },
    //查询企业
    async search() {
      this.loading = true
      this.queryInfo.userSchema=this.parteninfo.id
      const res = await companylist(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list

        this.total = res.data.data.total
        this.loading = false
        if(this.idsList.length){
          this.idsList.forEach(el=>{
            this.listData.forEach((item) => {
          if(el.id==item.id){
            this.$set(item, 'checked1', true)
          }
        })
          })
        }else{
          this.listData.forEach((item) => {
          this.$set(item, 'checked1', false)
        })
        }
      
      } else {
        this.$message.error(res.data.message)
      }
    },
        //升序排序
        clickUp1(item) {
      this.queryInfo.orders = item.asc 
      this.search()
      item.color1 = '#0099ff'
      item.color2 = ''
    },
    //降序排序
    clickDown1(item) {
      this.queryInfo.orders = item.desc
      this.search()
      item.color1 = ''
      item.color2 = '#0099ff'
    },
    handleCheckAllChange(val) {
      if (val) {
        this.listData.forEach((item) => {
          this.ids.push(item.reportId)
          this.idsList.push(item)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.listData.forEach((item) => {
          this.ids = []
          this.idsList = []
          this.$set(item, 'checked1', false)
        })
      }
      this.isIndeterminate = val
    },

    //跳转新增
    handleAdd() {
      this.$router.push({
        name: 'addenterprise'
      })
    },
    //编辑企业
    editcustom(item) {
      console.log(item)
      this.$router.push({
        name: 'cpmpanyinfo',
        query: { id: item.id }
      })
    },
    //选择企业
    latechange(bol, row) {
      const index = this.ids.indexOf(row.id)
      if (index == -1) {
        this.ids.push(row.reportId)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idsList.splice(index, 1)
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
  /deep/ .el-radio-button__inner {
    border: none;
    margin-right: 12px;
    border-radius: 4px;
  }
  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }
  ::v-deep .el-radio-button:first-child .el-radio-button__inner {
    border-left: 1px solid #fff;
    border-radius: 4px 0 0 4px;
    box-shadow: none!important;
}
::v-deep .el-upload-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
}
::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg) scaleY(0);
  width: 4px;
  transition: transform 0.15s ease-in 50ms;
  transform-origin: center;
}
::v-deep .enterprise_dialog .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 300px;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
.ckeckbox_text {
  position: relative;
  top: 5px;
  left: 10px;
      /deep/.el-checkbox__inner {
        width: 18px;
        height: 18px;
      }
      /deep/.el-checkbox__label {
        color: #333;
      }
      /deep/.el-checkbox__inner::after {
        height: 10px;
        left: 6px;
      }
    }
.zcname {
  background: rgba(78, 147, 251, 0.1);
  border: 0.5px solid #4e93fb;
  border-radius: 2px;
  font-size: 14px;
  color: #4e93fb;
  display: inline-block;
  padding: 2px 6px;
}
.partnerName {
  background: rgba(255, 125, 24, 0.1);
  border: 0.5px solid #ff7d18;
  border-radius: 2px;
  font-size: 14px;
  color: #ff7d18;
  display: inline-block;
  padding: 2px 6px;
  margin-left: 10px;
}
.label_list {
  display: flex;
  align-items: center;
  margin-top: 8px;
  .label {
    cursor: pointer;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    margin-right: 15px;
    cursor: pointer;
  }
  .acitve_label {
    padding: 0 10px;
    cursor: pointer;
    height: 28px;
    color: #146aff;
    background: #cbdeff;
    border-radius: 2px;
  }
}
.Subtag_list {
  padding: 10px;
  min-height: 77px;
  background: #f5f6f7;
  margin-top: 10px;
  display: flex;
  cursor: pointer;
  .label_title {
    width: 70px;
    flex-shrink: 0;
    white-space: nowrap;
    // span {
    //   font-family: 'PingFang SC';
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 12px;
    //   line-height: 17px;
    //   margin-right: 15px;
    //   color: #7c7f8e;
    // }
  }
  .label_box {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    div {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height */
      margin-right: 10px;
      color: #1f212b;
      // margin-bottom: 10px;
      height: 20px;
      padding: 5px 10px;
    }
    white-space: pre-wrap;
    .acitve_label {
      // height: 28px;
      color: #146aff;
      background: #cbdeff;
      border-radius: 2px;
      cursor: pointer;
    }
  }
}
.enterprise_dialog {
  .tip {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
  }
  .enterprise_box {
    .enterprise_item {
      margin-top: 10px;
      width: 387px;
      padding: 10px;
      background: #f4f4f4;
      border-radius: 1px;
      display: flex;
      justify-content: space-between;
      .left {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        /* identical to box height */

        color: #151515;
      }
      .right {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        color: #fd5469;
      }
    }
  }
}
.user {
  .form_info {
    background: #ffffff;
    padding: 20px;
  }

  .tab_txt {
    margin-top: 20px;
    padding: 0px;
    min-height: 60vh;
    .main {
  display: flex;
  flex-direction: column;
}

.arrUp {
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #c6c6c6;
  cursor: pointer;
}

.arrDown {
  width: 0;
  height: 0;
  margin-top: 2px;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-top: 8px solid #c6c6c6;
  cursor: pointer;
}
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  .storeCenter_item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
   
    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }
    .Relevancy {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #ff7d18;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .Relevancyblue {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #146aff;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .storeCenter_item_top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 14px;
      position: relative;
      background: #ffffff;
      padding: 16px 20px 10px 10px;
    
      .po_reportingStatus {
        position: absolute;
        color: #f53939;
        top: 50%;
        transform: translateY(-50%);
        right: 300px;
        img{
          width: 76px;
          height: 60px;
        }
      }
      .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;
        width: 40%;
        img {
          width: 90px;
          height: 90px;
          border-radius: 4px;
          margin-right: 14px;
        }

        .storeCenter_item_top_left_flex {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .reporting {
            border-radius: 8px;
            padding: 5px;
            font-size: 14px;
            color: #ff8c06;
            background: #fff4e6;
            margin-right: 5px;
          }
          .img_commer {
            display: flex;
            border: 1px solid #146aff;
            border-radius: 39px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #146aff;
            }
            .color_img {
              background: #146aff;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }
          .img_commers {
            display: flex;
            border: 1px solid #ff9c00;
            border-radius: 39px;
            margin: 0px 10px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #ff9c00;
            }
            .color_img {
              background: #ff9c00;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }

          h4 {
            color: #1f2d3d;
            font-size: 26px;
            font-weight: 500;
            margin: 0;
          }

          .storeCenter_item_top_left_flex_tag {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-content: center;
            padding-top: 13px;

            span {
              box-sizing: border-box;
              padding: 1px 6px;
              display: block;
              font-size: 14px;
              font-weight: 400;
              border-radius: 4px;
            }

            .office {
              border: 1px solid #559eff;
              color: #4e93fb;
              cursor: pointer;
            }

            .support {
              border: 1px solid #fd523f;
              color: #fd523f;
              margin-left: 12px;
            }
          }

          .address {
            font-size: 14px;
          }
        }
      }
      .storeCenter_item_top_center {
        display: flex;
       width: 30%;
        .count_box {
          height: 80px;
          width: 80px;
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          & > div:nth-of-type(1) {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            color: #151515;
          }
          & > div:nth-of-type(2) {
            margin-top: 20px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #151515;
          }
        }
      }
      .storeCenter_item_top_right {
        align-items: stretch;
        height: 100%;
        width: 30%;
        .account_cycle {
          text-align: right;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;

          /* 灰色字体 */

          color: #969495;
        }
        .operation_area {
          margin-top: 30px;
          float:right;
          display: flex;
          justify-content: space-between;
          align-items: center;
          div{
            margin-left: 10px;
          }
          .buttom_right {
            height: 36px;
            line-height: 1px;
          }
          .edit {
            padding-left: 20px;
            color: #4e93fb;
            cursor: pointer;
          }
          .frozen {
            padding-left: 20px;
            color: #fd5469;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
